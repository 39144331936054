import React from 'react'

import Layout from '../../../components/layout'
import Container from '../../../components/container'
import ResidenceDetails from '../../../components/residence-details'

import Process1 from '../../../images/work/18_PB_WILDCAT/PROCESS/WIL_01.jpg'
import Process2 from '../../../images/work/18_PB_WILDCAT/PROCESS/WIL_02.jpg'
import Process3 from '../../../images/work/18_PB_WILDCAT/PROCESS/WIL_03.jpg'
import Process4 from '../../../images/work/18_PB_WILDCAT/PROCESS/WIL_04.jpg'
import Process5 from '../../../images/work/18_PB_WILDCAT/PROCESS/WIL_05.jpg'
import Process1s from '../../../images/work/18_PB_WILDCAT/PROCESS/450X280/WIL-SM_01.jpg'
import Process2s from '../../../images/work/18_PB_WILDCAT/PROCESS/450X280/WIL-SM_02.jpg'
import Process3s from '../../../images/work/18_PB_WILDCAT/PROCESS/450X280/WIL-SM_03.jpg'
import Process4s from '../../../images/work/18_PB_WILDCAT/PROCESS/450X280/WIL-SM_04.jpg'
import Process5s from '../../../images/work/18_PB_WILDCAT/PROCESS/450X280/WIL-SM_05.jpg'

const Residence18 = () => (
  <Layout>
    <Container>
      <ResidenceDetails
        residenceTitle="WILDCAT RESIDENCE"
        residenceLocation="PEBBLE BEACH, CALIFORNIA"
        infoList={['New 2,840 SF single family residence with attached 2-car garage and 375 SF guesthouse.', 'Design - 2018']}
        testimonialList={[]}
        photos={[]}
        photosSmall={[]}
        processPhotos={[Process1, Process2, Process3, Process4, Process5]}
        processPhotosSmall={[Process1s, Process2s, Process3s, Process4s, Process5s]}
      />
    </Container>
  </Layout>
)

export default Residence18;
